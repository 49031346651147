import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import ReactTable from "react-table-6";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import CustomInput from "components/CustomInput/CustomInput.js";

import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pagination from "components/Pagination/Pagination2.js";

import BusinessResponseModal from "../suppliers/BusinessResponseModal";
import InteractionCallModal from "../calls/InteractionCallModal.js";

import CheckIcon from "@material-ui/icons/Check";
import ScheduleIcon from "@material-ui/icons/Schedule";

import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";

import { updateTeamTask } from "redux/actions/TeamTasksActions.js";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import loginStore from "../../redux/stores/LoginStore";

const customStyle = {
  ...styles,
  button: {
    padding: 0,
    margin: 0,
  },
};

const useStyles = makeStyles(customStyle);

const EnhancedTeamTasksList = (props, context) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [teamTasksArray, setTeamTasksArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [showFinished, setShowFinished] = useState(false);
  const initialRender = useRef(true);

  const currentUser = loginStore.getLoginUser();

  const [modal, setModal] = useState({
    open: false,
    id: null,
    type: null,
  });

  const fetchData = (page, pageSize, showFinished, filters = []) => {
    setLoading(true);

    const params = {
      page: page + 1,
      page_size: pageSize,
      done: showFinished,
      ...(Array.isArray(filters) ? filters : []).reduce(
        (acc, filter) => ({ ...acc, ...filter }),
        {}
      ),
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/user-tasks/`, { params })
      .then((response) => {
        const data = response.data.results.map((item) => {
          return {
            ...item,
            done_button: doneButton(item.id, item.assigned_to, item.done),
            view_button: viewButton(item.source_id, item.source_type),
          };
        });
        setTeamTasksArray(data);
        setLoading(false);
        setPages(Math.ceil(response.data.count / pageSize));
        setTotalCount(response.data.count);
      })
      .catch((error) => {
        console.log("Error fetching team tasks", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    fetchData(page, pageSize, showFinished);
  }, [page, pageSize, showFinished]);

  const handleViewButtonClick = (id, type) => {
    setModal({
      open: true,
      id: id,
      type: type,
    });
  };

  const viewButton = (source_id, source_type) => {
    return (
      <Button
        style={{ cursor: "pointer" }}
        title={context.t("View Item:") + source_id}
        simple
        color="info"
        value={source_id}
        className={classes.button}
        onClick={(e) =>
          handleViewButtonClick(e.currentTarget.value, source_type)
        }
      >
        <VisibilityIcon />
      </Button>
    );
  };

  const toggleDoneButton = (id, value) => {
    const payload = { done: value };
    dispatch(updateTeamTask(id, payload));
    setTeamTasksArray((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            done: value,
            done_button: doneButton(item.id, item.assigned_to, value),
          };
        }
        return item;
      });
    });
  };

  const doneButton = (id, assigned_to, done) => {
    return (
      <div
        style={{ cursor: "pointer" }}
        title={done === true ? "Re-Open item " + id : "Close/Finish Item " + id}
      >
        <Button
          simple
          color="info"
          // disabled={currentUser && currentUser.id !== assigned_to}
          value={id}
          className={classes.button}
          onClick={(e) => toggleDoneButton(e.currentTarget.value, !done)}
        >
          {done === true ? (
            <CheckIcon
              fontSize="small"
              style={{ color: "green", verticalAlign: "middle" }}
            />
          ) : (
            <ScheduleIcon
              fontSize="small"
              style={{ color: "orange", verticalAlign: "middle" }}
            />
          )}
        </Button>
      </div>
    );
  };

  return (
    <GridContainer>
      <BusinessResponseModal
        open={modal.open && modal.type === "Business Response"}
        value={modal.id}
        onClose={() => setModal({ ...modal, open: false })}
      />
      <InteractionCallModal
        open={
          modal.open && (modal.type === "Call" || modal.type === "KPI UPDATE")
        }
        value={modal.id}
        onClose={() => setModal({ ...modal, open: false })}
      />

      <GridContainer justifyContent="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={showFinished}
              onClick={() => {
                setShowFinished(!showFinished);
                setPage(0);
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={context.t("Show finished")}
        />
      </GridContainer>

      <GridItem xs={12}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader>
            <h4>
              {context.t("Search Results")} ({context.t("Found")}-{totalCount})
            </h4>
          </CardHeader>
          <CardBody>
            <GridItem>
              <ReactTable
                PaginationComponent={Pagination}
                filterable={true}
                data={teamTasksArray}
                pages={pages}
                manual
                onFetchData={(state) => {
                  const filters = state.filtered.map((filter) => ({
                    [filter.id]: filter.value,
                  }));
                  fetchData(state.page, state.pageSize, showFinished, filters);
                }}
                defaultSorted={[
                  {
                    id: "created_at",
                    desc: true,
                  },
                ]}
                columns={[
                  {
                    Header: context.t("ID"),
                    accessor: "id",
                    width: 60,
                    filterable: true,
                  },
                  {
                    Header: context.t("Source ID"),
                    accessor: "source_id",
                    width: 110,
                    filterable: true,
                  },
                  {
                    Header: context.t("Source Type"),
                    accessor: "source_type",
                    filterable: true,
                  },
                  {
                    Header: context.t("Assigned by"),
                    accessor: "assigned_by_name",
                    filterable: true,
                  },
                  {
                    Header: context.t("Assigned to"),
                    accessor: "assigned_to_name",
                    filterable: true,
                  },
                  {
                    Header: context.t("Deadline"),
                    accessor: "deadline",
                    width: 100,
                    filterable: true,
                  },
                  {
                    Header: "",
                    accessor: "done_button",
                    sortable: false,
                    width: 30,
                  },
                  {
                    Header: "",
                    sortable: false,
                    accessor: "view_button",
                    width: 30,
                  },
                ]}
                defaultPageSize={10}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageJump={false}
                loading={loading || !currentUser}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

EnhancedTeamTasksList.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default EnhancedTeamTasksList;
