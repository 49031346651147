import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { Chart } from "react-google-charts";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Utils from "../../services/utils";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles((theme) => ({
  ...styles,
  root: {
    flexGrow: 1,
  },
  largeStatContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    margin: theme.spacing(1, 0, 0, 0),
  },
  smallStatContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    margin: theme.spacing(0, 0, 1, 0),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  gaugeContainer: {
    textAlign: "center",
    width: "200px",
    height: "200px",
  },
  chartContainerHolder: {},
}));

const barColors = {
  CLOSED: "#F0F8FF",
  OPEN: "#FFC0CB",
  UPCOMING: "grey",
};

function sortObject(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}

const SupplierKPIStatus = {
  OLD_UNRESOLVED: 4,
  CLOSED: 3,
  SOME_PROGRESS: 2,
  NO_PROGRESS: 1,
};

export default function SupplierKpisTimelineChart({ filters }, context) {
  const classes = useStyles();
  const kpiCategories = useSelector((state) => state.kpisReducer);
  const supplierKPIsMap = useSelector(
    (state) => state.suppliersReducer.supplierKPIs
  );

  const supplierKpiUpdatesArray = useSelector(
    (state) => state.suppliersReducer.supplierKPIUpdateItems
  );
  const [selectedCategory, setSelectedCategory] = useState(5);
  const [drawChart, setDrawChart] = useState(Utils.giveMeGuid());

  const kpi_category_id_order = [1, 4, 5, 8, 9];

  const handleResize = useCallback(() => {
    if (!window.screenTop && !window.screenY) {
      setDrawChart(Utils.giveMeGuid());
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const filteredSupplierKpiUpdates = useMemo(() => {
    return supplierKpiUpdatesArray.filter(
      (kpiUpdate) =>
        kpiUpdate.calls?.length > 0 &&
        filters.supplier === kpiUpdate.supplier &&
        kpiUpdate.category_id === selectedCategory &&
        moment(kpiUpdate.opened_at).isBetween(
          filters.startTime,
          filters.endTime
        )
    );
  }, [filters, selectedCategory, supplierKpiUpdatesArray]);

  const processKpiUpdate = (kpiUpdate, supplierKPIsMap, kpiUpdatesMap) => {
    const {
      status,
      supplier_kpi,
      opened_at,
      closed_at,
      overview_date,
    } = kpiUpdate;
    const currentKpiStatus = supplierKPIsMap[supplier_kpi]?.status;
    const currentKpiClosedAt = supplierKPIsMap[supplier_kpi]?.closed_at;

    if (
      (status === SupplierKPIStatus.NO_PROGRESS ||
        status === SupplierKPIStatus.OLD_UNRESOLVED) &&
      currentKpiStatus === SupplierKPIStatus.CLOSED &&
      !closed_at
    ) {
      return;
    }

    if (
      currentKpiStatus === SupplierKPIStatus.OLD_UNRESOLVED &&
      !currentKpiClosedAt
    ) {
      kpiUpdatesMap[supplier_kpi] = {
        ...kpiUpdatesMap[supplier_kpi],
        [opened_at]: { closed_at: overview_date },
      };
    } else {
      kpiUpdatesMap[supplier_kpi] = {
        ...kpiUpdatesMap[supplier_kpi],
        [opened_at]: { closed_at },
      };
    }
  };

  const generateBarsForKpi = (sortedKpiHistory, supplierKPIsMap, filters) => {
    const data = [];
    const name = supplierKPIsMap?.description || "";

    Object.keys(sortedKpiHistory).forEach((openedAt, index) => {
      const previousKpi = data[data.length - 1];

      const gapBar = [
        name,
        "",
        barColors.CLOSED,
        previousKpi && previousKpi[0] === name
          ? moment(previousKpi[4])
          : moment(filters.startTime),
        moment(openedAt),
      ];

      if (gapBar[3] > gapBar[4]) {
        gapBar[4] = gapBar[3].clone().add(30, "days");
      }
      data.push(gapBar);

      const getBarEndTime = () => {
        const closedAt = sortedKpiHistory[openedAt].closed_at;
        if (closedAt && moment(closedAt).isBefore(filters.endTime)) {
          return moment(closedAt);
        } else if (moment().isBefore(filters.endTime)) {
          return moment();
        }
        return moment(filters.endTime);
      };

      const bar = [name, "", barColors.OPEN, moment(openedAt), getBarEndTime()];

      if (bar[3] > bar[4]) {
        bar[4] = bar[3].clone().add(30, "days");
      }
      data.push(bar);

      if (index === Object.keys(sortedKpiHistory).length - 1) {
        const endTime = moment().isBefore(filters.endTime)
          ? moment().add(1, "hours")
          : moment(filters.endTime).add(1, "days");
        data.push([
          name,
          "",
          sortedKpiHistory[openedAt].closed_at
            ? barColors.CLOSED
            : barColors.OPEN,
          bar[4],
          endTime,
        ]);

        if (moment().isBefore(filters.endTime)) {
          data.push([
            name,
            "",
            barColors.UPCOMING,
            endTime,
            moment(filters.endTime),
          ]);
        }
      }
    });

    return data;
  };

  const chartData = useMemo(() => {
    const kpiUpdatesMap = {};

    filteredSupplierKpiUpdates.forEach((kpiUpdate) =>
      processKpiUpdate(kpiUpdate, supplierKPIsMap, kpiUpdatesMap)
    );

    return Object.keys(kpiUpdatesMap).reduce((data, supplierKpiId) => {
      const sortedKpiHistory = sortObject(kpiUpdatesMap[supplierKpiId]);
      return [
        ...data,
        ...generateBarsForKpi(
          sortedKpiHistory,
          supplierKPIsMap[supplierKpiId],
          filters
        ),
      ];
    }, []);
  }, [filteredSupplierKpiUpdates, filters, supplierKPIsMap]);

  const renderTabs = useCallback(() => {
    const items = kpi_category_id_order
      .map((kpiCategoryId) =>
        kpiCategories.kpicategoryitems.find((item) => item.id === kpiCategoryId)
      )
      .filter(Boolean);

    return items.map((category) => (
      <Tab label={category.name} value={category.id} key={category.id} />
    ));
  }, [kpiCategories.kpicategoryitems]);

  return (
    <div key={drawChart}>
      <GridContainer className={classes.marginBottom}>
        <Card>
          <CardHeader>
            <h3>{context.t("Supplier KPIs timeline chart")}</h3>
            {kpiCategories.fetchingKPICategories ||
            kpiCategories.kpicategoryitems.length < 1 ? (
              <p>{context.t("Fetching KPI categories..")}</p>
            ) : (
              <div className={classes.root}>
                <AppBar
                  style={{ backgroundColor: "#005B4C" }}
                  position="static"
                >
                  <Tabs
                    variant="scrollable"
                    aria-label={context.t("KPI Categories")}
                    value={selectedCategory}
                    onChange={(event, newValue) =>
                      setSelectedCategory(newValue)
                    }
                  >
                    {renderTabs()}
                  </Tabs>
                </AppBar>
              </div>
            )}
            {!filters.supplier && (
              <p className="mt-1" style={{ color: "red" }}>
                {context.t("Please select supplier from above filters.")}
              </p>
            )}
            {filters.supplier &&
              (filteredSupplierKpiUpdates.length < 1 ||
                !chartData ||
                chartData.length < 1) && (
                <p className="mt-1">
                  {context.t(
                    "This supplier doesn't have any KPI History for the chosen period of time and category"
                  )}
                </p>
              )}
          </CardHeader>
          <CardBody className={classes.chartContainerHolder}>
            {selectedCategory && chartData && chartData.length > 0 ? (
              <Chart
                width="100%"
                height="500px"
                chartType="Timeline"
                loader={<div>{context.t("Loading chart..")}</div>}
                data={[
                  [
                    { type: "string", id: context.t("KPI") },
                    { type: "string", id: "Status" },
                    { type: "string", role: "style" },
                    { type: "date", id: "Start" },
                    { type: "date", id: "End" },
                  ],
                  ...chartData,
                ]}
                options={{
                  hAxis: {
                    title: context.t("Time"),
                  },
                }}
              />
            ) : null}
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
}

SupplierKpisTimelineChart.contextTypes = {
  t: PropTypes.func.isRequired,
};
